.formInput{
    background-color: var(--main-color);
    height:45px;
    width:90%;
    margin:20px;
    color:var(--secondary-color);
    border:0px ;
    border-bottom:2px solid var(--main-color-light) ;
    border-right:2px solid  var(--main-color-light) ;
    border-radius:10px ;
    font-family: 'Montserrat', sans-serif;

}
.formInput:focus{
    border:2px solid var(--main-color-light) ;
}
/*PlaceHolder*/
input[type="text"]::placeholder{
    color:var(--secondary-color);
    font-size: 14px;
}
textarea::placeholder{
    color:var(--secondary-color);
    font-size: 14px;
}
.textarea{
    max-width: 100px;
    resize: none;
}
.formInputTextarea{
    height:100px;
    resize: none;
    width:90%;


}
.formButton{
    border: 0px solid black;
    border-radius:10px;
    width:200px;
    height:30px;
    font-size: 16px;;
    color: var(--secondary-color);
    border: 1.5px solid var(--secondary-color);
    transition:0.5s;
    background-color: transparent;
}
.formButton:hover{
    background-color: var(--secondary-color);
    color: var(--main-color);
    border: 1.5px solid var(--main-color);

}

input[type="file"]{
width:70%;
}

input[type="file"]::file-selector-button {
    border: 0px solid black;
    border-radius:10px;
    width:200px;
    height:30px;
    font-size: 16px;;
    color: var(--secondary-color);
    background:var(--main-color);
    
  }
  
  input[type="file"]::file-selector-button:hover {
    
  }
  .formInput{
    background-color: rgba(206, 206, 206, 0.68);
    color:black;
  }
  /*PlaceHolder*/
input[type="text"]::placeholder{
    color:rgb(16, 16, 16);
    font-size: 14px;

}
input[type="email"]::placeholder{
    color:rgb(16, 16, 16);
    font-size: 14px;
}
textarea::placeholder{
    color:rgb(16, 16, 16);
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    

}
.formButton{
    transition: 0.5s;
}
.formButton:hover{
    transform: scale(1.05);
}
.selectInput{
    width:80%;
}

