@media screen and (max-width: 700px){
  .NavBar{
  display: none;
}
}
@media screen and (min-width: 700px){
.MobileNavBar{
    display:none;
  }
.NavBar{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    position: fixed;
    list-style: none;
    margin: 0;
    background-color:rgba(255, 0, 0, 0);
    width:100%;    
    display: flex;
    align-items: center;
    z-index: 10;
}
.NavBarItems{
  margin-left: auto;
  margin-right: 10%;;
}
.NavBar li{
    padding-top: 20px;
    height: 40px;
    display: inline-block; 
    margin-right: 30px; 
    border-bottom: 5px solid #00000000;  
    font-family: "ProximaNovaMedium";
    font-size:20px;

}
.NavBar li:hover{
  border-bottom: 5px solid var(--secondary-color);
  ;
}
.Logo {
  height: 40px;
  margin-left: 40px;
  margin-right: 40px;
}
.Logo:hover{
  border-bottom: 0px;
}
}