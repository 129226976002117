.ArticlesPage{
    padding: 5%;
    padding-top:10%;
    
}
.ArticlesDisplay{
    display: flex;
    flex-direction: row;
}
.ArticleCard{
    width:300px;
    height:355px;
    margin-right:10px;
    padding: 10px;
    overflow: hidden;
    border: 1.5px solid var(--secondary-color);
    border-top-left-radius:30px;
    border-bottom-right-radius: 30px;
    
}