.Requests{
    padding-top: 10%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.rqstsData{
    width:90%;
}
.rqRow{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100px;
    align-items: center;
    
}
.rqData{
    width :12.5%;
    white-space: pre-line;
}
.t_hdr{
    font-size: large;
    font-weight:bold
}