.crd{
    padding-top: 10%;
    height: 100vh;

}
.subServicesContainer{
    padding-top:7%;
    padding-bottom: 7%;
    height:100%;
    display:flex;
    justify-content: center;
}
.subServicesForm{
    width:70%;
}
