.ServicePage{
    height:350vh;
    width:100%;
    text-align: center;
    position: relative;
    overflow: inherit;
}
.ServiceBackground{
    position: fixed;
    width:100%;
    height: 100%;
    opacity: 0.06;
}
.ServiceBackground img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.ServiceData{
    padding-top: 7%;
    position: absolute;
    width:80%;
    top: 0;
    left: 0;
    margin-left: 10%;
    margin-bottom: 10%;
    height: 100%;
    overflow: visible;
    z-index: 2;
}
.SubServices{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5%;
}
/* SubService*/
.SubServiceBox{
    margin:10px;
    background-color: rgba(160, 1, 245, 0.07);
    height:350px;
    width:40%;
    border:0px;
    border-top:1.5px solid var(--secondary-color);
}
.SubServiceData{
    position:relative;
    overflow: hidden;
}
.SubServiceImageContainer{
    position: absolute;
    width:100%;
    height: 350px;
    opacity:0.4;
    overflow: hidden;
    
}
.SubServiceImageContainer img{
    top:0;
    height:100%;
    width: 100%;
    object-fit:cover;
}
.SubServiceContent{
    padding:0px 10px 0px 10px;
    height: 350px;;
    position: relative;
    z-index: 2;
}

/********/
.ServiceTitle{
    font-weight: bold;
}
.ServiceDetails{
    font-size: 25px;

}
.SubServiceTitle{
    font-size: 30px;
    font-weight: bold;
    height: 20%;
    overflow: hidden;
}
.SubServiceDetails{
    font-size: 20px;
    font-weight: bold;
    height: 30%;
    overflow: hidden;
    text-align:justify ;
}
.reachout{
    height: 20%;
    margin-bottom: 50px;
}
@media screen and (max-width:1400px){
    .ServicePage{
        height: 450vh;
    }
}
@media screen and (max-width: 800px){
    .ServicePage{
        overflow: scroll;
    }
    .SubServiceBox{
        width:85%;
        height:450px;
    }
    .SubServiceImageContainer,.SubServiceContent{
        height: 450px;
    }
    .SubServiceDetails{
        font-size: 18px;
    } 
}
@media screen and (max-width:1400px){
    .ServicePage{
        height: 450vh;
    }
}