@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:opsz@6..12&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Montserrat&family=Nunito+Sans:opsz@6..12&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&family=Montserrat&display=swap');
@import url("LandingSection.css");
@import url("LandingSection2.css");
@import url("Navbar.css");
@import url("MobileNavbar.css");
@import url("WhyUs.css");
@import url("Forms.css");
@import url("crdPages.css");
@import url("ContactSection.css");
@import url("Article.css");
@import url("ImageDisplay.css");
@import url("AboutUs.css");
@import url("Services.css");
@import url("Service.css");
@import url("HowItWorks.css");
@import url("Animation.css");
@import url("MessageSent.css");
@import url("Requests.css");
@import url("WhoAreWe.css");
@import url("Swiper.css");
@font-face {
  font-family: 'ProximaNovaMedium';
  src: url('../fonts/ProximaNovaMedium.ttf') format('opentype');
}
:root {
  --quaternary-color:rgba(142, 17, 205, 0.696);
  --tertiary-color:rgb(51, 8, 84,0.2);
  --tertiary-color2:#6641ec75;
  --main-color: #ebf0f8;
  --main-color-dark:rgb(210, 219, 232);
  --main-color-light:#1b1b1b;
  --main-color-lighter:rgb(84, 83, 83);
  --primary-color:rgb(23, 23, 72);
  --primary-color-transparent:rgb(23, 23, 72,0.85);
  --primary-color-light:rgb(39, 39, 105);
  --secondary-color:#e2e6ec;
  --tertiary-color:#59cd6e;
  --main-color:rgb(0, 0, 25);
  --secondary-text-color:#808080;
}
.PB{
    font-family: 'Montserrat', sans-serif;
    position: relative;
    overflow: hidden;
    color:var(--secondary-color);
}
.App {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    overflow: hidden;
    padding: 0;
  }
  .home{
    display: flex;
  }
  .title{
    font-size:50px;
    font-weight: bold;
  }
  a{
    text-decoration: none;
    color:var(--primary-color)
  }
  .lightLink{
    color:var(--secondary-color)
  }
  ul {
    list-style-type:none;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-optical-sizing: auto;
  }
h1,h2,h3,h4{
  font-family: "ProximaNovaMedium";
  font-weight:bold;
}
h1{
  font-size: 45px;
}
h2{
  font-size: 40px;
}
h3{
  font-size:35px;
}
h4{
  font-size:30px;
}

.DarkTitle{
  color:var(--primary-color)
}
.LightTitle{
  color:var(--secondary-color)
}