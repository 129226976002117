@keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes hideMenu{
    0%{
      height: 0%;
    }
    100%{
      height:0px;
    }
  }
  @keyframes moveLeftRight1 {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
@keyframes moveLeftRight{
    0%{
        transform:translateX(-2000px);
    }

    100% {
      transform: translateX(0px);
    }

}
@keyframes moveDownUp{
  0%{
      transform:translateY(2000px);
  }

  100% {
    transform: translateY(0px);
  }

}
@keyframes showLogo{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 0.7;
    }
}
