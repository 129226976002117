.AboutUs{
    padding-top:7%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 18px;
}
.WelcomeSection{
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: bolder;
}
.AbtUsVContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top:5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    overflow: hidden;
    
}
.AbtUsV{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 10px 15px;
    width: 40%;
    height: 400px;
    margin: 20px;
    color:var(--secondary-color);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    border-bottom-right-radius: 60px;
    border-top:1.5px solid var(--secondary-color);
    transition: all 0.3s ease-in-out;
    background-color:rgba(250, 250, 250, 0.15);
    transition: all 0.3s ease-in-out;
}
.AbtUsVText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    height:50%;
    
}
.ImgContainerM{
    width:50%;
    padding: 10px;
}
.ImgContainerM img{
    width: 80%;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;

}
.AbtUsMText{
    width: 50%;
    padding: 20px;
    padding-top: 50px;
}
.ImgContainerV{
    width:40%;
    height:20%;
    padding: 5px;
}
.ImgContainerV img{
    width:50%;
}
.AbtUsMContainer{
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
    align-content: center;
    margin-bottom: 5%;
}
.AbtUsM{
    display: flex;
    flex-direction: row;
    margin: 20px ;
    align-items: center;
    justify-content: center;
    text-align: justify;
}
.Mbl{
    display: none;
}
@media screen and (max-width: 800px){
    .Dsk{
        display: none;
    }
    .Mbl{
        display: block;
    }
    .AboutUs{
        padding-left: 5%;
        padding-right: 5%;
        font-size: 14px;
    }
    .AbtUsM{
        flex-direction: column;
        margin:10px
    }
    .AbtUsMContainer{
        width:90%;;
    }
    .AbtUsMText{
        width:90%;
        padding: 5px;
        padding-top: 25px;
    }
   .ImgContainerM{
        width:90%;
   }
   .AbtUsV{
    width: 80%;
    height: 400px;
    padding-top:30px ;
}
.ImgContainerV{
    overflow: hidden;
    height:50%;

}
.AbtUsVText{
    height: 70%;
    font-size:16px;
}
.ImgContainerV img{
    width: 100%;
    
}

}