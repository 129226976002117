.LandingSection2{
    height: 100vh;;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-top: 0%;
    /*background-color: var(--primary-color);*/
    overflow: hidden;
    color: var(--primary-color);
    position:relative;
    
}
.heroSection{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.LandingSectionImage{
    position:absolute;
    padding-top: 7%;
    width:70%;
    animation: showLogo 3s ease-in-out;
    opacity:0.8
    ;
  }
.ImageSection{
    width:20%;
    padding-bottom: 5%;
    /*position: relative;*/
    z-index: 0;
}

/*****/
.PB_header{
    font-size: 55px;
    font-weight: bolder;
    margin-bottom: 3%;
    animation: moveDownUp 2s ease-in-out ;
    

}
.PB_headline, .PB_subHeadline{
    font-size: 35px;
    font-weight: bold;
    padding: 5px;
    margin-top:2%;
    animation: moveDownUp 2.5s ease-in-out ;
}
.PB_subHeadline{
    font-size: 30px;
    width:50%
}
.greenU{
    color: var(--tertiary-color);
}
.topOrnamentM{
    position:absolute;
    top:0;
    left:0;
    margin-left: -10px;
    height: 20%;
    opacity: 0.7;
    display: none;

}
.rightOrnament,.rightOrnamentM{
    position:absolute;
    height: 50%;
    opacity: 0.5;
}
.rightOrnament{
    bottom: 0;
    right:0;
    height: 90%;
    opacity: 0.9;
}
.TextSection{
    position: absolute;
    width:90%;
    padding-top: 7%;
    padding-left: 10%;
    padding-bottom: 10%;
    text-align: left;
    margin-top: 2%;
    z-index: 1;
    font-family: "ProximaNovaMedium";

}

.ReadMoreAbtUs{
    margin-top:10%;
    padding: 10px 30px 10px 30px;
    color:var(--secondary-color);
    border: 1.5px solid var(--primary-color);
    background-color: transparent;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 800;
    transition: all .5s ease-in-out;

}
.ReadMoreAbtUsAnimate{
    animation: moveDownUp 2.75s ease-in-out ;

}
.ReadMoreAbtUs:hover{
    transform: scale(1.1);
    
    
}
@media screen and (max-width: 800px){
.LandingSection2{
    flex-direction: column;
    padding-top: 2%;
    padding-left: 10%;
    padding-right: 10%;
    height: 95vh;
}

.PB_header{
    font-size: 45px;

}
.PB_headline, .PB_subHeadline{
font-size: 35px;
width:60%
}
.PB_subHeadline{
    font-size: 30px;
    width:70%

}
.rightOrnament{
    height: 50%;
    display: none;
}
.ReadMoreAbtUs{
    margin-left: 20px;

}
.ImageSectionM{
    padding-top: 20%;
    display: block;
    height: 90%;
}
.LandingSectionImage{
    width:100%;
    opacity:0.5;
    
  }
.ImageSection{
    display: none;
}
.rightOrnamentM{
    display: block;
    height: 50%;
}
.topOrnamentM{
    /*display: block;*/
   
}
}
 
/*****/
@media screen and (min-width: 800px){

    .rightOrnamentM{
        display: none;
    }
    .topOrnamentM{
        display: none;
    }
}