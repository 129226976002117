.ImagesDisplay{
    padding:20px;
    padding-top: 120px;
    padding-left: 50px;
    padding-right: 50px;
    height: 120vh;
}
.ImageDisplay{
    display: flex;
    flex-direction: row;
}
.IDImageContainer{
    transition: transform .2s;
    
    margin:15px;
    width:300px;
    border-radius:30px;
}
.IDImageContainer:hover{
    transform: scale(1.2);
}
.IDImage{
    width:100%;
}