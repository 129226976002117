.HowItWorks{
  padding-top:25px;
  padding-bottom: 5%;
}
.featuresContainer,.featuresContainer2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

}
.featuresContainer2{
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0px;
}

  .feature,.feature2 ,.feature3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 15px;
    width: 300px;
    height: 450px;
    margin: 20px;
    padding-top: 50px;
    color:var(--primary-color);
    border-radius: 20px;
    border-top:1.5px solid var(--secondary-color);
    transition: all 0.3s ease-in-out;
    background-color:var(--secondary-color);
    overflow: hidden;;
  }
  .feature22,.feature33{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 15px 0px 15px;
    height: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .feature22{
    width:22%;
    margin: 7px;
    margin-top: 0;
    margin-bottom: 0;

  }
  .feature22 img{
    height: 100%;
  }
  .feature33{
    width:2%;
    margin: 7px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .feature2{
    width:22%;
    height: 155px;
    margin: 7px;
    padding-top: 0;;
  }
  .feature3{
    width:2%;
    height: 155px;
    margin: 7px;
    background-color: transparent;

  }
  .ft2-wimg{
    background-color: var(--primary-color);

  }
  .feature2>img{
    height: 70%;
  }
  .feature3 img{
    width:200%;
  }
  .stepImage{
    padding-top: 5%;
    height: 35%;
    overflow: hidden;
  }
  .stepImage img{
  height: 100%;
  width:100%;
  object-fit: scale-down;
  }
  .stepTitle{
    height: 10%;
  }
  .stepTitle2{
    font-size: 25px;
    font-weight: 900;
    height: 1%;
  }
  .featureDescription2{
    color:var(--primary-color-light);
    font-weight: 700;
    height: 40%;
  }
  .featureDescription{
    color:var(--primary-color-light);
    font-weight: 700;
    height: 40%;
  }
  .feature:hover{
    transform: scale(1.05);
  }
  .hiw-mobile{
    display:none
  }

  @media screen and (max-width: 800px){
    .featuresContainer{
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .feature{
      width:300px;
      height:420px;
    }
    .feature2{
      padding-top: 20px;
      height: 50vh;
      width: 80%;
    }
    .hiw-mobile{
      padding-top: 5%;
      display: block;
      padding-bottom: 10%;
    }
    .hiw-desktop{
      display: none;
    }
    .stepTitle2{
      font-size: 45px;
    }
    .featureDescription2{
      font-size: 30px;
    }
}
@media screen and (min-width: 1600px){
  .featuresContainer{

  }
  
}
/************features2**************/
