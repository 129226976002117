
.contact{

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30px;
    background-color: var(--primary-color);

  }
  .ContactInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    
  }
  .IconContainer{
    width:10%;
    margin-right:20px;

  }
  .IconContainer img{
    width:100%;
    height: 100%;
    object-fit: cover;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  .ContactInfoData{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:50%;
    overflow: hidden;
  }
  .ContactInfoText{
    width:70%;
    margin-right: 5%;
    text-align: center;
  }
  @media screen and (max-width: 800px){
    .ContactInfoData{
      width:90%;
    }
    .ContactInfo{
      flex-direction: column;
    }
    .IconContainer{
      margin-right:10px
    }

  }
