@media screen and (max-width: 800px){
 .MobileNavBar{
  margin-top: 0;
  position: fixed;
  padding: 0px;
  width:100%;
  z-index: 10;
  height: 10px;
  
 }
.MenuIcon {
  padding-right: 20px;
  height: 100%;
  margin-left:70%;
}
.MobileNavBarItems{
  
  padding-top: 30%;
  position: relative;
  background-color: var(--main-color);
  width: 100%;
  height: 100vh;
  transition: transform 1s ease;
  transform: translateX(0vw);
  
}
.MobileNavBarItems hr{
  border :2px solid var(--secondary-color);
  width:70%;
}
.MobileNavBarItems li{
  margin-top:20px;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}
.MobileNavBarMenu{
  height: 50px;
  position: absolute;
  z-index:10;
  width:100%;
}

}
.hide{
  transform: translateX(100vw);
  z-index: 0;
  pointer-events: none;

}
