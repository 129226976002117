.WhyUs{
    margin-top:10px;
    margin-bottom: 10px;
}
.WhyUsContent{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-top:2%;
    padding-bottom: 2%;
    width:100%;
    
}
.WhyUsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 15px;
    width: 40%;
    height:250px;
    color: var(--secondary-color);
    border-top:2px solid var(--secondary-color);
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    background-color:rgba(255, 255, 255,0.15);
        ;
  }
.WhyUsBox h4{
    margin-top: 10px;
    height: 20%;   
}
.WhyUsBoxDetails{
    display: flex;
    /*flex-direction: row;*/
}
.WuTitle{
    height: 40%;
}
.WuDetails{
    height: 60%;
}
.wuImageBox{
    /*height: 50px;*/
    height: 0px;
}
.wuImageBox img{
    height: 100%;
    animation: moveLeftRight1 4s ease-in-out infinite;
}
.WhyUs p{
    height:70%;
    margin-top: auto;
    text-align: justify;
  
}
.wbdv1,.wbdv2{
    width:30%;
    margin-right: 30px;
    transition: all .5s ease-in-out;
}

.wbdv1:hover ,.wbdv2:hover{
transform: scale(1.05);    
}
.wbdv2{
    margin-top:7%;
    /*border-left: 20px solid var(--tertiary-color2);
    border-left: 5px solid var(--secondary-color);*/
}
.wbdv1{
    margin-top:7%;
    /*border-right: 20px solid var(--tertiary-color2);
    border-right: 5px solid var(--secondary-color);*/

}
@media screen and (max-width: 800px){
    .WhyUsBox{
      width:75%;
      height: 300px;
    }
    .WhyUsBoxDetails{
        flex-direction: column;
    }
    .wbdv1:hover ,.wbdv2{
        margin-top:10px;    
        }
}