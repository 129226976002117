.WhoAreWe{
    padding-top: 25px;
    color: var(--primary-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}
.topRightOrnament{
    position:absolute;
    top:0;
    right:0;
    margin-top: -2px;
    margin-right: -10px;
    height: 53%;
    opacity: 0.9;
}
.waw-welcome{
    width:70%;
    padding: 2%;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 800;
    text-align: justify;

}
.waw-desc{
    width:90%;
    padding: 2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.waw-mission,.waw-values,.waw-stewardship,.waw-involved{
    width:40%;
    height: 350px;
    margin: 10px;
    background-color: var(--secondary-color);
    padding: 2%;
    border-radius: 20px;
    margin-top: 2%;
    position: relative;
}
.BackgroundImageContainer{
    top:0;
    left:0;
    position: absolute;
    width:100%;
    height: 100%;
    opacity: 0.4;
    border-radius: 20px;
}
.BackgroundImageContainer img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    
   
}
.waw-text{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.waw-mission h3,.waw-values h3,.waw-stewardship h3,.waw-involved h3{
    width:40%;
}
.waw-mission .waw-description,.waw-values .waw-description,.waw-stewardship .waw-description,.waw-involved .waw-description{
    width:50%;
    text-align: justify;
    font-weight: 600;
}

@media screen and (max-width: 800px){
.waw-desc{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
}
.waw-text{
    flex-direction: column;
}
.waw-welcome{
    width:80%;
}
.waw-mission,.waw-values,.waw-stewardship,.waw-involved{
    width:90%;
    height: 450px;
    margin: 10px;
    background-color: var(--secondary-color);
    padding: 2%;
    border-radius: 20px;
    margin-top: 2%;
    position: relative;
}
}